import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Box } from "@mui/material";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import FormControlLabel from '@mui/material/FormControlLabel';


const TreeMenu = (props) => {


    const menu_dropdown_options = {
        '.MuiFormControlLabel-label': {
            fontSize: '12pt',
            margin: '1px 1px 1px',
            padding: '1px 1px 1px'
        },
        '.MuiSvgIcon-root': {
            fontSize: '12pt'
        },
        '.MuiButtonBase-root': {
            padding: '2px'
        }
    }

    const menu_tree_item__no_hierarchy = {
        '.MuiTreeItem-iconContainer': {
            width: '3px'
        }
    }


    const treeMenuRawData = props.data; // Expects data in a list format like: [{'id': 1, 'title': 'Item name', 'menu'; 'group/subgroup' }]
    const [checkedItems, setCheckedItems] = useState({});

    const isNull = (value) => (
        typeof (value) === 'undefined' || value === null
    )

    const categorizedMenuItems = treeMenuRawData.reduce( // grouping items into ones which has a menu defined vs those who don't have menu defined
        (acc, item) => {

            if (!isNull(item.menu)) {
                acc.withMenu.push(item);
            } else {
                acc.withoutMenu.push(item);
            }
            return acc;
        },
        { withMenu: [], withoutMenu: [] }
    );
    

    const buildTree = (items) => {
        const tree = {};

        items.forEach(({ id, menu, title }) => {
            const parts = menu.split("/");
            let current = tree;
            parts.forEach((part) => {
                if (!current[part]) {
                    current[part] = {};
                }
                current = current[part];
            });
            current[title] = { id };
        });
        return tree;
    };

    const renderTree = (nodes, path = "") => {
        return Object.keys(nodes).map((key) => {
            const newPath = path ? `${path}/${key}` : key;
            const node = nodes[key];
            return (
                <TreeItem key={newPath} itemId={newPath} label={
                node?.id ? (
                    <FormControlLabel
                        sx={menu_dropdown_options}
                        control={
                            <Checkbox
                                checked={!!checkedItems[newPath]}
                                onChange={() => handleCheck(newPath, node.id)}
                            />
                        }
                        label={key}
                    />
                ) : (
                    key
                )
                }>
                {node?.id ? null : renderTree(node, newPath)}
                </TreeItem>
            );
        });
    };

    const handleCheck = (path, id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [path]: !prev[path],
        }));
        const rawTreeMenuItem = treeMenuRawData.find((item) => item.id == id);
        props.onChange(rawTreeMenuItem);
        
    };

    const treeData = buildTree(categorizedMenuItems.withMenu);
    return (
        <Box sx={{ minHeight: 100, minWidth: 250 }}>
            <SimpleTreeView>
                {renderTree(treeData)}
                {categorizedMenuItems.withoutMenu.map(({ id, title }) => (
                    <TreeItem
                        key={id}
                        itemId={`${id}/`}
                        sx={menu_tree_item__no_hierarchy}
                        label={
                            <FormControlLabel
                                sx={menu_dropdown_options}
                                control={
                                    <Checkbox
                                        checked={!!checkedItems[id]}
                                        onChange={() => handleCheck(id.toString(), id)}
                                    />
                                }
                                label={title}
                            />
                        }
                    />
                ))}
            </SimpleTreeView>
        </Box>
    );

}

export default TreeMenu;